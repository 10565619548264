import { mapInitialData, InitialDataModel, defaultInitialData } from '../app.base/initialData';
import { httpResources } from '@whop/core';
import { InitialDataPayload } from '@whop/core/types.initialdata';
import { ProbablyNullable } from '@whop/backend/types';
import { FetchJsonFn } from '@whop/containers/types';
import { AppCacheType } from '@whop/app/types';

export async function loadInitialData(options: {
  initialDataMaybe: ProbablyNullable<InitialDataPayload>;
  fetchJson: FetchJsonFn;
  appCache: AppCacheType<unknown>;
}): Promise<InitialDataModel> {
  const { initialDataMaybe, fetchJson, appCache } = options;
  const cacheKey = 'initialData';
  const cache = appCache.getOrCreateCache('__internal__');
  const alreadyCached = cache.getValue(cacheKey, null);
  if (alreadyCached) {
    return alreadyCached as InitialDataModel;
  }

  const cacheValue = function <T>(data: T) {
    cache.setValue('initialData', data);
    return data;
  };

  // initialDataMaybe can also be empty object! (@review)
  if (initialDataMaybe && Object.keys(initialDataMaybe).length) {
    return cacheValue(mapInitialData(initialDataMaybe));
  }
  const result = await fetchJson<InitialDataPayload>({
    url: httpResources.initialData.url,
  });
  if (result.status === 'ok') {
    return cacheValue(mapInitialData(result.data));
  }
  return defaultInitialData;
}
