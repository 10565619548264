import { defineHttpResources } from '@whop/containers';
import {
  CartStatsModel,
  CartStatsPayload,
  CartDetailPayload,
  CartDetailModel,
  CartStateModel,
} from './types';
import { coerce } from '@whop/core/mapping';
import { mapBillingAddress, mapShippingAddress } from '@whop/business';
import { mapShippingOption, mapPaymentOption } from '@whop/order';

export const httpResources = defineHttpResources({
  // state
  cartDetail: { url: `/api/cart/`, key: 'cart-detail' },
  cartState: { url: `/api/cart/`, key: 'cart-state' },
  cartStats: { url: `/api/cart/less/` },
  // actions
  addItem: { url: `/api/cart/item/`, method: 'POST' },
  getCoupons: { url: `/api/cart/coupons/` },
  addCoupon: { url: `/api/cart/coupons/`, method: 'POST' },
  removeCoupon: { url: `/api/cart/coupons/`, method: 'DELETE' },
  addManyItems: { url: `/api/cart/items/`, method: 'POST' },
  setItemQuantity: (id: string) => ({ url: `/api/cart/item/${id}/`, method: 'PUT' }),
  removeItem: (id: string) => ({ url: `/api/cart/item/${id}/`, method: 'DELETE' }),
  updateCart: { url: '/api/cart/', method: 'PATCH' },
  uploadAttachments: { url: '/api/cart/attachments/', method: 'POST' },
  deleteAttachment: (id: string) => ({ url: `/api/cart/attachments/${id}/`, method: 'DELETE' }),
  // multiple
  clearCart: { url: `/api/cart/`, method: 'DELETE' },
  bulkImport: { url: `/api/cart/items/import-csv/`, method: 'POST' },
});

export function mapCartStats(payload: CartStatsPayload): CartStatsModel {
  return {
    total: coerce.num(payload.prices.total_discount_price_gross, 0),
    itemsCount: coerce.num(payload.items_count, 0),
  };
}

export function mapCartDetail(payload: CartDetailPayload): CartDetailModel {
  return {
    id: payload.id,
    allowedPaymentMethods: coerce.str(payload.allowed_payment_methods),
    prices: payload.prices,
    itemsCount: coerce.num(payload.items_count, 0),
    items: payload.items.map((item) => {
      return {
        cartItemId: coerce.str(item.id),
        variantId: coerce.str(item.variant),
        quantity: coerce.num(item.quantity, 0),
        statuses: item.statuses,
        prices: item.prices,
      };
    }),
    missing_items: payload.missing_items.map((missing_item) => {
      return {
        cartItemId: coerce.str(missing_item.id),
        variantId: coerce.str(missing_item.variant),
        quantity: coerce.num(missing_item.quantity, 0),
        statuses: missing_item.statuses,
        prices: missing_item.prices,
      };
    }),
    freeShippingFrom: coerce.num(payload.free_shipping_from, 0),
    freeShippingType: payload.free_shipping_type,
    freeShippingRemaining: coerce.num(payload.free_shipping_price, 0),
    minOrderFrom: coerce.num(payload.min_order_price, 0),
    shippingPriceFrom: payload.shipping_price_from,
    minOrderValue: coerce.num(payload.prices.total_discount_price_gross, 0),
    options: {
      makePartialOrderEnabled: !!payload.options.make_partial_order_enabled,
      importItemsFromFileEnabled: !!payload.options.import_items_from_file_enabled,
      workerSelectionEnabled: !!payload.options.worker_personal_number_select_enabled,
    },
    features: payload.features || [],
    ...mapCartState(payload),
  };
}

export function mapCartState(payload: CartDetailPayload): CartStateModel {
  return {
    billingAddress: payload.billing_address ? mapBillingAddress(payload.billing_address) : null,
    shippingAddress: payload.shipping_address ? mapShippingAddress(payload.shipping_address) : null,
    paymentMethod: payload.payment_method ? mapPaymentOption(payload.payment_method) : null,
    shippingMethod: payload.shipping_method ? mapShippingOption(payload.shipping_method) : null,
    note: coerce.str(payload.note),
    customerCode: coerce.str(payload.customer_custom_code),
    token: coerce.str(payload.token),
    attachments: (payload.attachments_test || []).map((file) => {
      const { id, path } = file;
      // @prototype(name): no better API right now
      const url = path;
      const name = url.split('/').pop() || url;
      return { id, url, name };
    }),
    companyWorkerPersonalNumber: payload.worker_personal_number,
  };
}
